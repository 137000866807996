<template>
    <nav class="nav">
        <router-link class="nav__link button button--secondary" :to="'/'">
            Locaties
        </router-link>
        <router-link class="nav__link button button--secondary" :to="'/inventory'">
            Vrachtwagen voorraad
        </router-link>
    </nav>
</template>

<script>
export default {
    name: "Navbar",
    setup() {

    },
    components: {
    }
}
</script>

<style lang="scss" scoped>
.nav {
    position: relative;
    display: flex;
    padding: 1.5rem 0;
    width: 100%;

    @include mq('mobile') {
        flex-direction: column;
    }

    &::after {
        content: '';
        display: block;
        width: 100vw;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: $primary;
        z-index: -1;
    }

    &__link {
        color: $white;
        font-size: 1.6rem;
        font-weight: 400;
        width: fit-content;

        &:not(:first-child) {
            margin-left: 1rem;

            @include mq('mobile') {
                margin-left: 0;
                margin-top: 1rem;
            }

        }
    }
}
</style>
