<template>
    <div class="card">
        <div class="card__header">
            <div class="card__header-container">
                <div v-if="state.isFranchise" class="card__header-franchise-badge">
                    Franchise
                </div>
                <h2 class="card__title">{{ state.name }}</h2>
            </div>
        </div>
        <div class="card__body">
            <p class="card__address mb-2">
                {{ state.street }} {{ state.streetNumber }}<br>
                {{ state.zipcode }} {{ state.city }}<br>
                {{ state.country }}
            </p>

            <div class="card__contact mb-3">
                <p class="card__contact-title">
                    Contact
                </p>
                <p class="card__contact-info">
                    {{ state.contact.name }}<br />
                    {{ state.contact.email }}<br />
                    {{ state.contact.phone }}
                </p>
            </div>
            <router-link class="button button--primary mb-2" :to="'/location/' + state.id">
                Bekijk locatie
            </router-link>
        </div>
    </div>
</template>

<script>
import { inject, reactive } from 'vue';
import Modal from '@components/ui/Modal.vue';

export default {
    name: "LocationCard",
    props: {
        location: Object,
    },
    setup(props) {
        const state = reactive({
            name: props.location.name,
            id: props.location.id,
            isFranchise: props.location.is_franchise,
            street: props.location.street,
            streetNumber: props.location.number,
            city: props.location.city,
            zipcode: props.location.zipcode,
            contact: props.location.contact,
            contactVisible: false
        });

        const showContact = () => {
            state.contactVisible = true;
        };

        const hideContact = () => {
            state.contactVisible = false;
        };

        return {
            state,
            showContact,
            hideContact
        };
    },
    components: {
        Modal,
    },
}
</script>

<style lang="scss" scoped>
.card {
    display: flex;
    flex-direction: column;
    background-color: $white;

    &__header {
        &-container {
            position: relative;
        }

        &-franchise-badge {
            position: absolute;
            top: -3rem;
            left: -1rem;
            font-size: 1rem;
            background-color: $primary;
            padding: .25rem .5rem;
        }

        background: $secondary;
        color: $white;
        text-align: center;
        padding: 4rem 2rem;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 2.4rem;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
    }

    &__address {
        font-weight: 300;
    }

    &__contact-title {
        font-weight: 400;
    }

    &__contact-info {
        font-weight: 300;
    }
}
</style>
