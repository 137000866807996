<template>
    <Layout/>
</template>
<script>

import Layout from '@components/layout/Layout.vue';
import { ref } from "vue"

export default {
    name: "App",
    setup() {

    },
    components: {
        Layout
    },
}
</script>
