<template>
    <div class="no-connection" v-if="state.errorResponse">
        <div class="wifi-icon">
            <font-awesome-icon icon="fa-solid fa-wifi-slash"/>
        </div>
    </div>
    <header class="header">
        <div class="wrapper header__container">
            <div class="header__logo">
                <router-link to="/">
                    <img src="@assets/images/hbm-logo.jpg"/>
                    <img v-if="!user.is_demo" class="ml-5" style="background: white; padding: 3px; max-width: 75px; height: 49px"
                         src="@assets/images/agreflor-logo.png"/>
                </router-link>
            </div>
            <div class="header__account">
                {{ user.name }}
                <a href="/logout" class="button button--primary-outline header__account-button">
                    <font-awesome-icon icon="fa-solid fa-arrow-right-from-bracket"/>
                </a>
            </div>
            <Navbar/>
        </div>
    </header>
</template>

<script>
import Navbar from './Navbar.vue';
import {inject, reactive} from 'vue'

export default {
    name: "Header",
    setup() {
        const user = inject('user')
        const state = reactive({
            errorResponse: false
        })

        window.addEventListener('successResponse', function() {
            state.errorResponse = false
        })

        window.addEventListener('errorResponse', function() {
            state.errorResponse = true
        })

        return {
            user,
            state
        }
    },
    components: {
        Navbar
    },
}
</script>

<style lang="scss" scoped>
.no-connection {
    position: fixed;
    background-color:red;
    width:100%;
    z-index: 999;
    min-height:5rem;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .wifi-icon {
        background-color:white;
        width: fit-content;
        height: fit-content;
        border-radius: 100%;
        padding: .5rem;

        svg {
            width: 2.5rem;
            height: 2.5rem;
        }
    }
}
.header {
    overflow-x: hidden;

    &__container {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        padding: 3rem;
        background-color: $primary;

        img {
            max-width: 7.5rem;
        }
    }

    &__account {
        display: flex;
        align-items: center;
        gap: 1rem;
        font-size: 1.6rem;
    }

    &__account-button {
        padding: 0.5rem 1rem;
        font-size: 1.2rem;
    }
}
</style>
