<template>
    <div class="card">
        <div class="card__header">
<!--            <img class="card__image" :src="state.image"/>-->
            <img class="card__image" @click="$emit('open-image', state.image)" :src="state.image"/>
            <h2 class="card__title">{{ state.name }}</h2>
        </div>
        <div v-if="!productAvailable" style="position:absolute;"
             class="row-space-center">
                <span class="card__product-label-red">
                    Uit assortiment
                </span>
        </div>
        <div class="card__body">
            <div class="row-space mb-2">
                <div class="row-space">
                    <p class="card__product-category ">
                        {{ state.category }}
                    </p>
                    <div v-for="label in product.labels">
                        <span class="card__product-label">
                            {{ label.name }}
                        </span>
                    </div>
                </div>
                <p>€{{ state.sellingPrice }}</p>
            </div>
            <p class="card__product-stock mb-3">
                <i
                    class="card__status-icon"
                    :class="[
              state.stock > 0 ? 'green' : 'red',
            ]"
                ></i>
                {{ state.stock }} laatste levering
            </p>
            <button v-if="!locationConfirmed && !hasStockChangedToday"
                    class="button button--primary"
                    v-on:click="editSupply">
                Voorraad wijzigen
            </button>
            <button v-if="!locationConfirmed && hasStockChangedToday"
                    class="button button__stock-changed"
                    v-on:click="editSupply">
                Voorraad wijzigen
            </button>
        </div>
    </div>
</template>

<script>
import {computed, reactive} from 'vue';

export default {
    name: "ProductCard",
    props: {
        product: Object,
        locationId: String,
        onEditSupply: Function,
        locationConfirmed: Boolean
    },
    setup(props) {
        const state = reactive({
            name: props.product.name,
            id: props.product.id,
            image: props.product.image,
            category: props.product.category,
            productCode: props.product.productcode,
            purchasePrice: props.product.purchaseprice,
            sellingPrice: props.product.sellingprice,
            stock: props.product.stock,
            locationId: props.locationId,
            today: props.product.today.date,
            product: props.product
        });

        const productAvailable = computed(() => {
            let result = false
            let today = new Date()
            props.product.product_availability.forEach(pa => {
                let start = new Date(pa.start_availability)
                start.setHours(0, 0, 0, 0)
                let end = new Date(pa.end_availability)
                end.setHours(0, 0, 0, 0)
                today.setHours(0, 0, 0, 0)
                if (start <= today && end >= today) {
                    result = true
                }
            })
            return result
        })


        const hasStockChangedToday = computed(() => {
            const today = new Date(state.today)
            const lastStockChange = new Date(state.product.lastStockChange.date)
            return (lastStockChange.getDate() === today.getDate() &&
                lastStockChange.getMonth() === today.getMonth())
        })

        const editSupply = () => {
            props.onEditSupply(state.id, productAvailable);
        }

        return {
            state,
            editSupply,
            productAvailable,
            hasStockChangedToday
        };
    },
    components: {},
}
</script>

<style lang="scss" scoped>
.button {
    &__stock-changed {
        background-color: $white !important;
        color: $primary !important;
    }
}

.card {
    display: flex;
    flex-direction: column;
    background-color: $white;


    &__header {
        position: relative;
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        justify-content: bottom;
        align-items: flex-end;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 50%;
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
            z-index: 1;
            transition: all 0.1s ease-in-out;
        }

        &:hover {
            cursor: pointer;
            &::after {
                background: linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%);
                height: 10%;
            }

            .card__title {
                transition: all 0.15s ease-in-out;
                opacity: 0;
                z-index: -9;
            }
        }
    }

    &__image {
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        z-index: 0;
        width: 100%;
        min-height: 6rem;
    }

    &__title {
        color: $white;
        font-size: 2rem;
        grid-column: 1 / span 1;
        grid-row: 1 / span 1;
        padding: 2rem;
        z-index: 2;
    }

    &__body {
        display: flex;
        flex-direction: column;
        padding: 1.6rem;
    }

    &__product-category {
        font-weight: 700;
    }

    &__product-stock {
        font-weight: 300;
    }

    &__product-label {
        display: inline-block;
        padding: 0.50em 0.8em;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        color: white;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        background-color: #7e90c2;
        margin-left: 0.50em;
    }

    &__product-label-red {
        display: inline-block;
        padding: 0.50em 0.8em;
        font-size: 100%;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        color: white;
        white-space: nowrap;
        vertical-align: baseline;
        border-radius: 0.25rem;
        background-color: red;
        margin-left: 0.50em;
    }

    &__status-icon {
        border-radius: 50%;
        width: 1.2rem;
        height: 1.2rem;
        margin-right: 0.5rem;
        display: inline-block;

        &.green {
            background-color: $succes;
        }

        &.red {
            background-color: $danger;
        }
    }


}

.row-space {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.row-space-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
}


</style>
