<template>
    <page-title v-bind:title="'Voorraad vrachtwagen'" />
    <div style="display: flex; flex-direction: column">
        <div class="sub-title-container">
            <div class="wrapper">
                <span class="sub-title">Assortiment</span>
            </div>
        </div>
    </div>
    <div class="wrapper">
        <div class="inventory__container">
            <table>
                <thead>
                    <tr>
                        <th>Naam</th>
                        <th>In vrachtwagen</th>
                        <th>In magazijn</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="product in state.products" :key="product.identifier">
                        <td class="product__name">{{ product.name }} ({{ product.identifier }})</td>
                        <td>
                            <div class="form__input-group">
                                <div class="form__minus" @click="decrement(product.id)">
                                    -
                                </div>
                                <input class="form__input" id="voorraad-input" type="number" min="0"
                                    v-model="state.inventory[product.id]" />
                                <div class="form__plus" @click="increment(product.id)">
                                    +
                                </div>
                            </div>
                        </td>
                        <td>{{ product.stock }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="confirmation-container">
        <button class="button button--primary" @click="saveInventory">
            Opslaan
        </button>
    </div>
</template>

<script>
import { reactive, inject, onMounted } from 'vue'
import PageTitle from '@components/ui/PageTitle.vue'
import Modal from "@components/ui/Modal.vue"
import apiClient from '@services/apiClient'

export default {
    name: "TruckInventory",
    setup() {

        const user = inject('user')

        const state = reactive({
            products: [],
            inventory: {}
        })

        const fetchInventory = () => {
            apiClient.get('/inventory').then((resp) => {
                state.products = resp.data.products
                state.products.forEach((product) => {
                    let truckInventory = product.user_truck_inventory?.amount ?? 0
                    state.inventory[product.id] = truckInventory
                })
            })
        }

        const saveInventory = () => {
            apiClient.post('/inventory', {
                inventory: state.inventory
            }).then((resp) => {

            })
        }

        const increment = (identifier) => {
            state.inventory[identifier] += 1
        }

        const decrement = (identifier) => {
            if (state.inventory[identifier] > 0) {
                state.inventory[identifier] -= 1
            }
        }

        onMounted(() => {
            fetchInventory()
        })

        return {
            state,
            increment,
            decrement,
            saveInventory
        }
    },
    components: {
        PageTitle
    }
}
</script>

<style lang="scss" scoped>
.inventory {
    &__container {
        margin-top: 1rem;
        margin-bottom: 6rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        overflow-x: auto;

        table {
            min-width: 680px;
        }

        tbody {
            tr {
                td {
                    font-size: 1.2rem;
                    padding-top: 1rem;
                }
            }
        }
    }
}

.product__name {
    max-width: 50%;
}

.form__minus {
    margin-left: 0;
}
</style>


