<template>
  <div>
    <Header/>
    <router-view/>
  </div>
</template>

<script>
import Header from './Header.vue';
import { useHead } from '@vueuse/head'

export default {
  name: "Layout",
  setup() {
      useHead({
          title: 'HBM Logistic - Chauffeurs',
          link: [
              {
                  rel: 'icon', sizes: '32x32', href: '/images/favicon/cropped-flower-32x32.png'
              }, {
                  rel: 'icon', sizes: '192x192', href: '/images/favicon/cropped-flower-192x192.png'
              }
          ]
      })
  },
  components: {
    Header
  }
}
</script>

<style lang="scss">

.form {
  &__select {
    width: 12rem;
    height: 3rem;
    border: 1px solid $primary;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    font-size: 1.4rem;
    background-image: url('data:image/svg+xml;charset=utf8,<svg viewBox="0 0 8 8" xmlns="http://www.w3.org/2000/svg"><path fill="%23fff" d="M0 0h8v8H0z"/><path d="M2 0h4l-2 2-2-2z" fill="%23000"/></svg>');
    background-repeat: no-repeat;
    background-position: right 0.5rem bottom -0.4rem;
    background-size: 2rem;

  }
}

.filter {
  display: flex;
  flex-direction: column;
  padding-top: 2.4rem;

  label {
    font-weight: 700;
    margin-bottom: 0.5rem;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 1.5;
    color: #212529;
    text-align: left;
  }
}

</style>
