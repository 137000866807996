import { createWebHistory, createRouter } from "vue-router";
import Locations from "@pages/Locations.vue";
import Location from "@pages/Location.vue";
import TruckInventory from "@pages/TruckInventory.vue";

const routes = [
  {
    path: "/",
    name: "Locations",
    component: Locations,
  },
  {
    path: "/location/:id",
    name: "Location",
    component: Location,
  },
  {
    path: "/inventory",
    name: "Inventory",
    component: TruckInventory
  }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
