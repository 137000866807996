<template>
    <div class="main-bg">
        <page-title v-if="state.locationName" v-bind:title="'Voorraad filiaal ' + state.locationName" />
        <div style="display: flex; flex-direction: column">
            <div class="sub-title-container">
                <div class="wrapper">
                    <span class="sub-title">Assortiment</span>
                </div>
            </div>
            <div class="products">
                <div class="products__container wrapper">
                    {{ state.productsChanged }}
                    <product-card :location-confirmed="state.locationConfirmed"
                        v-for="product in state.products.availableProducts" v-bind:key="product.id + product.stock"
                        v-bind:product="product" v-bind:locationId="state.locationId" v-bind:onEditSupply="startEditSupply"
                        @open-image="showImg" />
                </div>
            </div>
        </div>
        <hr class="divider">
        <div v-if="state.products.unavailableProducts.length > 0" style="display: flex; flex-direction: column">
            <div class="sub-title-container">
                <div class="wrapper">
                    <span class="sub-title">Uit assortiment</span>
                </div>
            </div>
            <div class="products">
                <div class="products__container wrapper">
                    {{ state.productsChanged }}
                    <product-card :location-confirmed="state.locationConfirmed"
                        v-for="product in state.products.unavailableProducts" v-bind:key="product.id + product.stock"
                        v-bind:product="product" v-bind:locationId="state.locationId" v-bind:onEditSupply="startEditSupply"
                        @open-image="showImg" />
                </div>
            </div>
        </div>
    </div>

    <Modal v-if="state.editSupply" v-bind:modalVisible="state.editSupply" v-bind:onClose="stopEditSupply">
        <product-form v-bind:product="state.activeProduct" v-bind:locationId="state.locationId"
            v-bind:onSubmitSucces="stopEditSupply" :product-available="state.productAvailable" />
    </Modal>

    <Modal v-if="state.confirmationModal" v-bind:modalVisible="state.confirmationModal"
        v-bind:onClose="hideConfirmationModal">
        <location-confirmation v-bind:products="state.products" v-bind:locationId="state.locationId"
            v-bind:isFranchise="state.locationIsFranchise" @confirmationDone="confirmedLocation" />
    </Modal>

    <div class="confirmation-container">
        <button class="button button--primary"
            v-if="!state.locationConfirmed && !state.confirmationModal && !state.editSupply" @click="showConfirmationModal">
            Bevestig filiaal
        </button>
        <div class="row" v-if="state.locationConfirmed">
            <p>Filiaal is bevestigd door <span v-if="state.stockChangeConfirmation.user">{{
                state.stockChangeConfirmation.user.name }}</span>
                <span v-else>'onbekend'</span> om
                {{ state.stockChangeConfirmation.formatted_confirmation_date }} op
                {{ state.stockChangeConfirmation.formatted_created_at_in_time }}
            </p>
            <button class="button button--primary " @click="restoreStockChangeConfirmation" style="margin-left: 2rem"
                v-if="state.locationConfirmed">
                Herstel wijziging
            </button>
        </div>
    </div>
    <vue-easy-lightbox :visible="visibleRef" :imgs="lightboxRef" @hide="onHide"></vue-easy-lightbox>
</template>

<script>
import { inject, reactive, ref } from 'vue';
import ProductCard from '../components/product/ProductCard.vue';
import Modal from '@components/ui/Modal.vue';
import PageTitle from '@components/ui/PageTitle.vue';
import ProductForm from '@components/product/ProductForm.vue';
import LocationConfirmation from '@components/location/LocationConfirmation.vue'
import apiClient from '@services/apiClient'
import { faSledding } from '@fortawesome/pro-solid-svg-icons';

export default {
    name: "Location",
    setup() {
        const state = reactive({
            products: {
                availableProducts: [],
                unavailableProducts: []
            },
            locationId: null,
            locationName: null,
            locationIsFranchise: false,
            editSupply: false,
            activeProduct: {},
            confirmationModal: false,
            locationConfirmed: false,
            stockChangeConfirmation: null,
            productAvailable: true
        });

        const showConfirmationModal = () => {
            state.confirmationModal = true
        }

        const hideConfirmationModal = () => {
            state.confirmationModal = false
        }

        const setLocationId = (locationId) => {
            state.locationId = locationId;
        }

        const setLocationName = (locationName) => {
            state.locationName = locationName;
        }

        const setLocationConfirmed = (confirmed) => {
            state.locationConfirmed = confirmed
        }

        const setLocationIsFranchise = (isFranchise) => {
            state.locationIsFranchise = isFranchise
        }

        const setProducts = (availableProducts, unavailableProducts) => {
            state.products.availableProducts = availableProducts;
            state.products.unavailableProducts = unavailableProducts
        }

        const setStockChangeConfirmation = (data) => {
            state.stockChangeConfirmation = data;
        }

        const startEditSupply = async (productId, productAvailable) => {
            let productList = productAvailable.value ? state.products.availableProducts : state.products.unavailableProducts
            state.activeProduct = productList.find(product => product.id === productId);
            state.editSupply = true;
            state.confirmationModal = false
        }

        const stopEditSupply = () => {
            state.editSupply = false;
            state.activeProduct = null;
            fetchProducts();
        }

        const fetchProducts = () => {
            apiClient.get(`/location/${state.locationId}`)
                .then(response => {
                    setProducts(response.data.availableProducts, response.data.unavailableProducts)
                    setLocationName(response.data.locationName)
                    setLocationConfirmed(response.data.is_confirmed)
                    setLocationIsFranchise(response.data.is_franchise)
                    setStockChangeConfirmation(response.data.stock_change_confirmation)
                })
                .catch(error => console.log(error));
        }

        const restoreStockChangeConfirmation = () => {
            apiClient.post(`/location/${state.locationId}/restore`)
                .then(response => {
                    fetchProducts()
                })
                .catch(error => console.log(error));
        }

        const confirmedLocation = () => {
            state.confirmationModal = false
            fetchProducts()
        }

        const visibleRef = ref(false)
        const lightboxRef = ref('')
        const showImg = (img) => {
            console.log(img)
            lightboxRef.value = img
            visibleRef.value = true
        }
        const onHide = () => visibleRef.value = false


        return {
            state,
            setLocationId,
            setProducts,
            startEditSupply,
            stopEditSupply,
            fetchProducts,
            showConfirmationModal,
            hideConfirmationModal,
            confirmedLocation,
            restoreStockChangeConfirmation,
            visibleRef,
            showImg,
            onHide,
            lightboxRef
        };
    },
    mounted() {
        this.setLocationId(this.$route.params.id);
        this.fetchProducts();
    },
    components: {
        ProductCard,
        Modal,
        PageTitle,
        ProductForm,
        LocationConfirmation
    },


}
</script>

<style lang="scss" scoped>
.divider {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5rem;
    height: 1px;
    display: block;
    background-color: #7e90c2;
    max-width: 125rem;

}

.main-bg {
    background-color: #fbfbfb
}

.products {
    background-color: $light-grey;
    padding: 5rem 0 0 0;

    &__container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(21rem, 1fr));
        gap: 4.5rem;
        padding-top: 6rem;
        padding-bottom: 6rem;
    }
}

.row {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.recover-button {
    max-height: 1px !important;
    margin-left: 2rem;
    margin-bottom: 2rem;
    padding: 2rem;
}
</style>
