<template>
    <form v-on:submit="verifyInput">
        <div class="form" v-if="!state.prompts.notEnoughInWarehouse && !state.prompts.promptForStockChange">
            <div v-if="productAvailable.available" class="form__flex-col mb-2">
                <div>Start datum: {{ productAvailable.startDate }}</div>
                <div>Eind datum: {{ productAvailable.endDate }}</div>
            </div>
            <div class="form__warning" v-if="state.stockWarning.show">
                <p>
                    {{ state.stockWarning.message }}
                </p>
            </div>
            <div class="form__control-group">
                <p>Voorraad wijzigen voor:</p>
                <p>{{ state.product.name }}</p>
            </div>
            <hr class="form__divider">
            <div class="form__control-group mb-2">
                <p>Voorraad laatste levering</p>
                <p>{{ state.product.stock }}</p>
            </div>
            <div class="form__control-group mb-2">
                <p>Bestaande voorraad in de winkel</p>
                <div class="form__input-group">
                    <div class="form__minus" v-on:click="calculateLeftOver(); checkIfDervingExceeds()">
                        -
                    </div>
                    <input class="form__input" id="leftover-input" type="number" min="0" v-model="enteredLeftoverRef" />
                    <div class="form__plus hidden" style="visibility: hidden;!important" v-on:click="() => {
                        enteredLeftoverRef++;
                    }">
                        +
                    </div>
                </div>
            </div>
            <hr class="form__divider">
            <div class="form__control-group mb-2">
                <p>Aantal retour van deze voorraad</p>
                <div class="form__input-group">
                    <div class="form__minus" v-on:click="() => {
                        enteredDervingRef--;
                        enteredDervingRef < 0 ? enteredDervingRef = 0 : null;
                    }">
                        -
                    </div>
                    <input class="form__input" id="derving-input" type="number" min="0" :max="state.product.stock"
                        v-model="enteredDervingRef" @blur="() => {
                            enteredDervingRef > state.product.stock ? enteredDervingRef = state.product.stock : null;
                            enteredDervingRef < 0 ? enteredDervingRef = 0 : null;
                        }" />
                    <div class="form__plus" v-on:click="calculateDerving">
                        +
                    </div>
                </div>
            </div>

            <div v-if="productAvailable.available" class="form__control-group mb-2">
                <p>Aantal nieuwe stuks geplaatst</p>
                <div class="form__input-group">
                    <div class="form__minus" v-on:click="() => {
                        enteredSupplyRef--;
                        enteredSupplyRef < 0 ? enteredSupplyRef = 0 : null;
                    }">
                        -
                    </div>
                    <input class="form__input" id="voorraad-input" type="number" min="0" v-model="enteredSupplyRef" @blur="() => {
                        enteredSupplyRef < 0 ? enteredSupplyRef = 0 : null;
                    }" />
                    <div class="form__plus" v-on:click="() => {
                        enteredSupplyRef++;
                        // enteredSupplyRef > state.product.stock ? enteredSupplyRef = state.product.stock : null;
                    }">
                        +
                    </div>
                </div>
            </div>

            <hr class="form__divider">
            <span class="form__updated_at">Voorraad laatst aangepast: {{ product.updated_at }}</span>
            <button class="button button--primary form__button" type="submit">Opslaan</button>
        </div>
        <div v-if="state.prompts.notEnoughInWarehouse" class="text-lg">
            <p>Weet je zeker dat je dit wilt doen?</p>
            <p>Ingevulde aantal overschrijdt voorraad magazijn!</p>
            <div class="form__flex form__mt">
                <button @click="submit" class="button--primary button form__btn-width">Ja</button>
                <button @click="state.prompts.notEnoughInWarehouse = false"
                    class="button--primary button form__btn-width">Nee
                </button>
            </div>
        </div>
        <div v-if="state.prompts.promptForStockChange" class="text-lg">
            <div class="form__text-center form__mt">
                <p>De nieuwe voorraad is nu: {{ enteredLeftoverRef + enteredSupplyRef - enteredDervingRef }}</p>
                <p>Klopt dit?</p>
            </div>

            <div class="form__flex form__mt">
                <button @click="submit" class="button--primary button form__btn-width">Ja</button>
                <button @click="state.prompts.promptForStockChange = false" class="button--primary button form__btn-width">
                    Nee
                </button>
            </div>
        </div>
    </form>
</template>

<script>
import { computed, inject, reactive, ref, watch } from 'vue';
import apiClient from '@services/apiClient'

export default {
    name: "ProductForm",
    props: {
        product: Object,
        locationId: String,
        onSubmitSucces: Function,
    },
    created() {
        this.getProductCount()
    },

    setup(props) {
        const user = inject('user')

        const state = reactive({
            product: props.product,
            locationId: props.locationId,
            wareHouseProduct: null,
            prompts: {
                notEnoughInWarehouse: false,
                promptForStockChange: false
            },
            stockWarning: {
                show: false,
                message: ''
            },
            today: props.product.today.date,
            productAvailability: props.product.product_availability
        });
        const enteredDervingRef = ref(0);
        const enteredSupplyRef = ref(0);
        const enteredLeftoverRef = ref(state.product.stock);

        //Methods
        const getProductCount = async () => {
            apiClient.get(`/product/${state.product.id}`).then((resp) => {
                state.wareHouseProduct = resp.data
            })
        }

        const verifyInput = async (e) => {
            e.preventDefault();
            const wareHouseStock = state.wareHouseProduct.stock;
            if (enteredSupplyRef.value > 0 && enteredSupplyRef.value > wareHouseStock) {
                state.prompts.notEnoughInWarehouse = true
            } else {
                state.prompts.promptForStockChange = true
            }
        }

        const checkIfDervingExceeds = () => {
            const enteredDerving = enteredDervingRef;
            const enteredLeftover = enteredLeftoverRef;

            if (enteredDerving.value > enteredLeftover.value) {
                enteredDerving.value = enteredLeftover.value
            }
        }

        const calculateLeftOver = () => {
            let enteredLeftover = enteredLeftoverRef;
            enteredLeftover.value--;
            enteredLeftover.value < 0 ? enteredLeftover.value = 0 : null;
            return enteredLeftover.value;
        }

        const calculateDerving = () => {
            const enteredDerving = enteredDervingRef;
            const enteredLeftover = enteredLeftoverRef;
            enteredDerving.value++;
            enteredDerving.value > enteredLeftover.value ? enteredDerving.value = enteredLeftover.value : null;
            return enteredDerving.value;
        }

        const submit = async (e) => {
            const enteredDerving = enteredDervingRef;
            const enteredSupply = enteredSupplyRef;
            const enteredLeftover = enteredLeftoverRef;
            const locationId = state.locationId;
            const productId = state.product.id;

            if (!locationId && !productId) {
                return;
            }

            if (!enteredDerving && !enteredSupply) {
                return;
            }
            if ((enteredLeftover.value + enteredSupply.value - enteredDerving.value) >= 0) {
                apiClient.post(`/location/${locationId}/product/${productId}`, {
                    loss: enteredDerving.value,
                    new_delivered: enteredSupply.value,
                    left_over: enteredLeftover.value,
                }).then((resp) => {
                    if (resp.status === 200) {
                        props.onSubmitSucces();
                    }
                })
            }
        }

        //Computed
        const productAvailable = computed(() => {
            let result = {
                available: false,
                startDate: null,
                endDate: null
            }
            state.productAvailability.forEach(pa => {
                if (pa.start_availability <= state.today && pa.end_availability >= state.today) {
                    result.available = true
                    result.startDate = pa.start_availability
                    result.endDate = pa.end_availability
                }
            })
            return result
        })

        watch(enteredLeftoverRef, async (newValue, oldValue) => {
            if (newValue > state.product.stock) {
                enteredLeftoverRef.value = state.product.stock
                state.stockWarning.show = true
                state.stockWarning.message = "Bestaande voorraad in de winkel kan niet groter zijn dan de voorraad bij de laatste levering. Als dit toch het geval is, neem dan contact op met de administratie."
            }
        })

        return {
            state,
            enteredDervingRef,
            enteredSupplyRef,
            enteredLeftoverRef,
            verifyInput,
            submit,
            getProductCount,
            productAvailable,
            calculateLeftOver,
            checkIfDervingExceeds,
            calculateDerving,
            user
        }
    },
    components: {},
}
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &__updated_at {
        font-size: 1.5rem;
    }

    &__control-group {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__divider {
        margin: 1rem 0;
        height: 1px;
        display: block;
        background-color: $grey;
    }

    &__button {
        align-self: flex-end;
    }

    &__flex {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }

    &__mt {
        margin-top: 1rem;
    }

    &__text-center {
        text-align: center;
    }

    &__btn-width {
        min-width: 7rem;
    }


}
</style>
