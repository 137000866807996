<template>
    <Modal v-if="state.showNotifications"
           :notificationModal="true"
           :modalVisible="state.showNotifications"
           :onClose="closeNotificationModal">
        <div class="notification-container">
            <div class="notification-header">
                <span></span>
                <h2>Let op!</h2>
                <div class="modal__close" @click="closeNotificationModal">
                    <font-awesome-icon class="close-mark" icon="fa-solid fa-xmark"/>
                </div>
            </div>
            <div v-for="(notification, index) in state.notifications" :key="index">
                <p class="notification-message">{{ notification.message }}</p>
            </div>
        </div>
    </Modal>
    <page-title title="Locaties"/>
    <div class="locations">
        <div class="filter wrapper">
            <label for="route-filter">Routes</label>
            <select name="search" class="form__select" id="route-filter" v-model="selectedRouteNumber">
                <option
                    v-for="number in state.routeNumbers"
                    :value="number.id"
                >
                    {{ number.number }}
                </option>
            </select>
        </div>
        <div class="locations__container wrapper">
            <LocationCard
                v-for="location in state.locations"
                v-bind:key="location.id"
                :user="user"
                v-bind:location="location"/>
        </div>
    </div>
</template>

<script>
import {reactive, inject, watch, ref} from 'vue';
import LocationCard from '@components/location/LocationCard.vue';
import PageTitle from '@components/ui/PageTitle.vue';
import apiClient from '@services/apiClient'
import Modal from "../components/ui/Modal.vue";

export default {
    name: "Locations",
    setup() {

        const user = inject('user')

        const state = reactive({
            locations: [],
            routeNumbers: [],
            notifications: [],
            showNotifications: false,
        });
        const selectedRouteNumber = ref(user.userinfo.routenumber_id)

        function setLocations(locations) {
            state.locations = locations;
        }

        function closeNotificationModal() {
            state.showNotifications = false
            setNotificationsRead()
        }

        function setNotificationsRead() {
            let notificationUserIds = []

            state.notifications.forEach(notification => {
                notificationUserIds.push({
                    id: notification.notification_user_id
                })
            })

            let payload = {
                user_id: user.id,
                notification_user_ids: notificationUserIds
            }

            apiClient.put(`/notification/${user.id}/update`, payload).then((resp) => {
                console.log(resp.data)
            })
        }

        function setRouteNumbers(routeNumbers) {
            state.routeNumbers = routeNumbers
        }

        function setMessages(notifications) {
            state.notifications = notifications
        }

        function fetchNotification() {
            apiClient.get(`/notification/${this.user.id}`).then((resp) => {
                setMessages(resp.data)
                if (resp.data.length > 0) {
                    state.showNotifications = true
                }
            })
        }

        function fetchLocations() {
            const query = selectedRouteNumber ? `?routeNumber=${selectedRouteNumber.value}` : ''
            apiClient.get(`/locations${query}`)
                .then(response => {
                    setLocations(response.data.locations)
                    setRouteNumbers(response.data.routeNumbers)
                })
                .catch(error => console.log(error));
        }

        watch(selectedRouteNumber, () => {
            fetchLocations()
        })

        return {
            state,
            fetchLocations,
            fetchNotification,
            closeNotificationModal,
            user,
            selectedRouteNumber
        };
    },
    mounted() {
        this.fetchLocations()
        this.fetchNotification()

    },
    components: {
        LocationCard,
        PageTitle,
        Modal
    }
}
</script>

<style lang="scss" scoped>
.locations {
    background-color: $light-grey;

    &__container {
        display: grid;
        grid-template-columns: repeat(3, minmax(24rem, 1fr));
        gap: 4.5rem;
        padding-top: 1.6rem;
        padding-bottom: 6rem;

        @include mq('laptop-sm') {
            grid-template-columns: repeat(2, minmax(24rem, 1fr));
        }

        @include mq('tablet') {
            grid-template-columns: repeat(1, minmax(24rem, 1fr));
        }
    }
}

.close-mark {
    color: $white;
    background-color: black;
    font-size: 2rem;
    border-radius: 50%;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    text-align: center;
    line-height: 2rem;
    cursor: pointer;
}


.notification-header {
    background-color: #7a91c4;
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 1rem;
    color:white
}

.notification-header h2 {
    font-size: 24px;
    color: white;
    margin: 0;
}

.notification-message {
    font-size: 20px;
    color: #000;
    margin: 10px 0;
    padding: 2rem;
}

.notification-container {
    min-width: 600px;
}

</style>


